import { useEffect, useRef } from 'react';
/**
 * setInterval doesn't work quite well with react hooks for instance we need
 * a custom hook that solves the issue
 *
 * Reference:
 * https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 *
 * By: Dan Abramov :god:
 *
 * @param callback
 * @param delay - the interval number, null to prevent the interval from
 * executing
 */
function useInterval(callback, delay) {
  var savedCallback = useRef();

  // Remember the latest callback.
  useEffect(function () {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(function () {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current();
      }
    }
    if (delay) {
      var id = setInterval(tick, delay);
      return function () {
        return clearInterval(id);
      };
    }
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return function () {};
  }, [delay]);
}
export default useInterval;